body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* stint-ultra-expanded-regular - latin */
@font-face {
  font-family: 'Stint Ultra Expanded';
  font-style: normal;
  font-weight: 400;
  src: url('/font/stint-ultra-expanded-v18-latin/stint-ultra-expanded-v18-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('/font/stint-ultra-expanded-v18-latin/stint-ultra-expanded-v18-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/font/stint-ultra-expanded-v18-latin/stint-ultra-expanded-v18-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/font/stint-ultra-expanded-v18-latin/stint-ultra-expanded-v18-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('/font/stint-ultra-expanded-v18-latin/stint-ultra-expanded-v18-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/font/stint-ultra-expanded-v18-latin/stint-ultra-expanded-v18-latin-regular.svg#StintUltraExpanded') format('svg');
  /* Legacy iOS */
}

/* josefin-sans-regular - latin */
@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/font/Josefin_Sans/josefin-sans-v23-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('/font/Josefin_Sans/josefin-sans-v23-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/font/Josefin_Sans/josefin-sans-v23-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/font/Josefin_Sans/josefin-sans-v23-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('/font/Josefin_Sans/josefin-sans-v23-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/font/Josefin_Sans/josefin-sans-v23-latin-regular.svg#JosefinSans') format('svg');
  /* Legacy iOS */
}