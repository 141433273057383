#biografie {
  width: 100%;
}

#biografie h1 {
  margin-bottom: 0;
  margin-top: 1.5rem;
}

#biografie .container-image {
  width: 100%;
}

#bio-title {
  color: #b81c3f;
}

#biografie .container-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vw;
}

#bio-image {
  display: block;
  height: auto;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}

#biografie p {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  margin: auto;
}

.fiftyfifty {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .fiftyfifty {
    display: block;
  }
    #biografie p {
    width: auto;
    margin: 1rem;
  }
  #biografie .container-text {
    display: initial;
  }

}
