.row {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  justify-content: center;
  margin: 1rem;
}



#galerie video {
  display: block;
  width: 100%;
}

.column {
  -ms-flex: 25%; /* IE10 */
  flex: 22%;
  max-width: 24%;
  padding: 0 1rem;
}

#galerie video{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.column img {
  margin-bottom: 1rem;
  vertical-align: middle;
  width: 100%;
}

#galerie-title {
  color: #3bebb9;
  padding: 0;
}

#galerie img:hover {
  filter: blur(0px);
  opacity: 1;
  box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.767);
  transform: scale(1.02);
  transition: all 0.5s;
}


@media screen and (max-width: 1400px) {
  .column {
    -ms-flex: 47%;
    flex: 47%;
    max-width: 48%;
    padding: 0 0.5rem;
  }
}

@media screen and (max-width: 1000px) {
  .column {
    -ms-flex: 90%;
    flex: 98%;
    max-width: 98%;
    padding: 0 0.5rem;
  }
}

@media screen and (max-width: 500px) {
#galerie h1 {
  margin-top: 0
  ;
}
}
