body,html {
  margin: 0;
  padding: 0;
}

.body{
  height: calc(100vh - 1rem - 4rem);
}


p {
  font-size: 1.2rem;
  color: #A3C4D9;
  text-align: justify;
  font-family: 'Josefin Sans', Times, serif;
}

body{
  background-color: #012c3a;
}

.logo{
  height: calc(100vh - 1rem - 4rem);
  writing-mode: vertical-lr;
  text-orientation: mixed;
  text-align: center;
  font-size: 4rem;
  color: #3eb489;
  color: #8EEB2F;
  font-family: 'Stint Ultra Expanded', Times, serif;
  margin-right: 1vw;
}
.content{
  display: flex;
  justify-content: center;
  width: 100%;
}



@media screen and (orientation: landscape) and (max-height: 500px)   {
  .logo {
    height: calc(100vw - 1rem - 4rem);
  }
}
