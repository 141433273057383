.button{

  color:white;
 margin-right: 8px;
 margin-bottom: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
background-color: #844AF6;


;
border-radius: 13px;
text-decoration: none;
  font-family: 'Josefin Sans';
  font-size: 1.2rem;
  white-space: pre-wrap;
}


.button:hover  {
  box-shadow: 0 3px 6px black;
}
