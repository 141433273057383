.home img {
  width: 100%;
  height: auto;
  margin: 0;
  object-fit: contain;
  box-sizing: border-box;
}

.home {
  position: relative;
  height: 100vh;
  min-height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
}

.home ul {
  padding: 0;
}

body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
}

.home .title {
  position: absolute;
  top: 1%;
  left: 2%;
  font-size: 4rem;
  text-align: left;
  color: white;
  font-family: "Stint Ultra Expanded";
}

.navigation-container {
  position: absolute;
  bottom: 8vh;
  left: 2%;
}

@media screen and (max-width: 600px) {
  .home .title {
    top: 1vh;
    left: 2%;
    font-size: 2rem;
    margin: 0;
  }

  .home img {
    width: auto;
    height: 100vh;
    margin: 0;
    object-fit: contain;
    box-sizing: border-box;
  }
}
