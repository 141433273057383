.card {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
 
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-radius: 12px;
  max-width: 500px;
}

.card p{
  color:black;
}


.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}


.container-card {
  padding: 16px;
}

.card-buttons{
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}

.card-title{
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 2rem;
}
.card-image {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}