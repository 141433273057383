#aktuelles{
  width: 100%;
}

#aktuelles .container-image{
  overflow: hidden;
  width: 100%;
}

#aktuelles .container-text{
  display: flex;
  flex-direction: column;
}

#aktuelles-img{
  max-width: 100%;
  display: block;
}

#aktuelles .container-cards{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  margin-left: 1rem;
  margin-right: 1rem;

}

#aktuelles img{
  width: 100%;
 
    vertical-align: middle
}

#aktuelles-title{
  color: #D852EB;
}

.fourtysixty{
  display: grid;
  grid-template-columns: 40% 1fr;
  width: 100%;
}

.fourtysixty div{
overflow: hidden;
}


@media screen and (max-width: 1000px) {
  .fourtysixty{
    display: block;
  }
}

@media screen and (max-width: 500px) {
.button{
  justify-items: center;
}
}