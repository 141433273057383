body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
}

.fadeIn {
  animation: 0.5s fadeIn forwards;
}

.fadeIn_landing {
  animation: 2s fadeIn_landing forwards;
}


 h1{
  font-family: "Stint Ultra Expanded";
  font-size: 6vh;
  font-weight: lighter;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: left;
  margin-left: 1.5rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(0px, 100vh);
  }

  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeIn_landing {
  from {
    opacity: 0;
    transform: translate(-20px, 0);
  }

  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@media screen and (max-width: 600px) {
h1{
  font-size: 3.5rem;
  
}

}


