.landing_navigation li {
  list-style-type: none;
}

.landing_navigation a {
  color: white;
  font-size: 1.2rem;
  font-family: "Josefin Sans";
  text-decoration: none;
}

.landing_navigation a:hover {
  color: #eba13b;
  font-size: 1.3rem;
}
